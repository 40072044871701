import { Field, Formik } from "formik";
import React, { useEffect } from "react";
import { useMutation } from "react-query";
import { useParams } from "react-router-dom";
import { postAnswer } from "../../../api/api";
import arrow_left_gray from "../../../assets/images/icons/arrow_left_gray.svg";
import arrow_right_white from "../../../assets/images/icons/arrow_right_white.svg";
import { Notify } from "../../../utils/notify";

export default function Question({ question, prevStep, nextStep, number }) {
    const { token } = useParams();
    const answerMutation = useMutation((values) => postAnswer(values));
    const hasSubQuestions = question.sub_questions && question.sub_questions.length > 0;
    const questions = [question, ...(question.sub_questions || [])];

    let stepperGoTo = number + 1;

    const onSubmit = (values, { setSubmitting, setErrors, resetForm }) => {
        values.answers = Object.entries(values.answers).reduce((acc, [key, answer]) => {
            if (Array.isArray(answer.value)) {
                answer.value = answer.value.join("|");
            }
            acc[key] = answer;
            return acc;
        }, {});

        answerMutation.mutate(values, {
            onSuccess: () => {
                nextStep(stepperGoTo);
                resetForm();
            },
            onError: (error) => {
                if (error.response?.data?.errors) {
                    setErrors(error.response.data.errors);
                } else {
                    Notify.error("Er is iets fout gegaan, probeer het later nogmaals.");
                }
                setSubmitting(false);
            },
        });
    };

    const getOtherInputBlock = (type, question) => {
        return (
            <>
                <div className={type} key="other">
                    {type === "radio" ? (
                        <Field id="other" name={`answers.${question.id}.value`} type={type} className="form-control" value={"other"} />
                    ) : (
                        <Field id="other" name={`answers.${question.id}.other`} type={type} className="form-control" />
                    )}
                    <label htmlFor="other">Anders, namelijk</label>
                </div>
                <Field className="form-control" name={`answers.${question.id}.other_value`} type="text" />
            </>
        );
    };

    const getInput = (question) => {
        switch (question.type) {
            case "multiple_choice":
                return (
                    <div className="mt-2">
                        {question.options.map((option, index) => (
                            <div className="radio" key={index}>
                                <Field
                                    name={`answers.${question.id}.value`}
                                    value={option.id}
                                    id={option.id}
                                    type="radio"
                                    className="form-control"
                                    onClick={() => (stepperGoTo = option.go_to ?? stepperGoTo)}
                                />
                                <label htmlFor={option.id}> {option.title} </label>
                            </div>
                        ))}
                        {question.has_other_option === 1 ? getOtherInputBlock("radio", question) : ""}
                    </div>
                );

            case "multiple_answer":
                return (
                    <div className="mt-2">
                        {question.options.map((option, index) => (
                            <div className="checkbox" key={index}>
                                <Field
                                    name={`answers.${question.id}.value`}
                                    value={option.id}
                                    id={option.id}
                                    type="checkbox"
                                    className="form-control"
                                />
                                <label htmlFor={option.id}> {option.title} </label>
                            </div>
                        ))}
                        {question.has_other_option === 1 ? getOtherInputBlock("checkbox", question) : ""}
                    </div>
                );
            default: //open
                return <Field autoFocus={true} name={`answers.${question.id}.value`} type="text" className="form-control" />;
        }
    };

    return (
        <div>
            <Formik
                initialValues={{
                    token: token,
                    answers: questions.reduce((acc, q) => {
                        acc[q.id] = {
                            value: "",
                            other: "",
                            other_value: "",
                        };
                        return acc;
                    }, {}),
                }}
                onSubmit={onSubmit}
                enableReinitialize={true}
            >
                {({ errors, touched, handleSubmit, isSubmitting }) => (
                    <form onSubmit={handleSubmit}>
                        {questions &&
                            questions.map((question, index) => (
                                <div key={index} className={hasSubQuestions ? "sub-question-content" : "content"}>
                                    <h3 className="subtitle">Vraag {number + (index > 0 ? `.${index}` : "")}</h3>
                                    <h1>{question?.title}</h1>
                                    <p dangerouslySetInnerHTML={{ __html: question?.description }} />

                                    <div className="form-group">
                                        <label htmlFor="">Jouw antwoord:</label>
                                        {getInput(question)}
                                        {errors[`answers.${question.id}`] && <div className="error">{errors[`answers.${question.id}`][0]}</div>}
                                    </div>
                                </div>
                            ))}
                        <div className="buttons_container">
                            <button className="btn kingkong btn-back" type="button" onClick={() => prevStep()}>
                                <img src={arrow_left_gray} alt="arrow" />
                                <span style={{ paddingLeft: "10px" }}>Terug</span>
                            </button>
                            <button type="submit" className="btn btn-primary kingkong">
                                <span>Volgende</span>
                                <img src={arrow_right_white} alt="arrow" />
                            </button>
                        </div>
                    </form>
                )}
            </Formik>
        </div>
    );
}
